<template>
  <v-container
  >
    <v-col cols='6'
    >
      <v-row>
        <v-file-input
          v-model="file"
          outlined
          dense
        ></v-file-input>
      </v-row>
      <v-row>
        <v-btn @click="migrar"
          v-if="!loading"
          class="info"
        >Aceptar</v-btn>
        <v-btn
            v-if="loading"
            class="info"
        >
          <v-progress-circular
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
/* eslint-disable camelcase */
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  data: () => ({
    file: '',
    loading: false,
  }),
  methods: {
    async migrar() {
      if (this.file) {
        this.loading = true;
        const data = new FormData();
        data.append('archivo', this.file);
        const queryMap = {
          method: 'post',
          url: `${API_URL}api/legajos/importarCsv`,
          data,
          headers: {
            token: localStorage.getItem('token'),
          },
        };
        const { data: { status, message } } = await axios(queryMap);
        if (status === 'error') console.error(message);
        this.loading = false;
        this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Migración exitosa!!',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Seleccione un archivo...',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
